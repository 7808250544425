.omnius-info-icon-success{
			background-color: darken($brand-success, 0%);
}

.omnius-info-icon-warning{
		background-color: darken($brand-warning, 0%);
}

.omnius-info-icon-danger{
		background-color: darken($brand-danger, 0%);
}

.omnius-info-icon-neutral{
		background-color: #000;
}
