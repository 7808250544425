$brand-color: #3099cc !default;
$brand-logo: '../../images/entities/omnius/logo.png' !default;
$brand-navbar-logo: $brand-logo !default;
$brand-bg-text-color: #fff !default;

/* latin */
@font-face {
	font-family: 'Titillium Web';
	font-style: normal;
	font-weight: 400;
	src: local('Titillium Web'), local('TitilliumWeb-Regular'), url(https://fonts.gstatic.com/s/titilliumweb/v4/7XUFZ5tgS-tD6QamInJTcZSnX671uNZIV63UdXh3Mg0.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

body {
	background-color: #f6f6f6;
	padding-top: 75px;
	padding-bottom: 30px;
	overflow-y: scroll;
	font-family: 'Titillium Web', sans-serif;

	@media screen and (max-width: 360px) {
		font-size: 13px;
	}
	@media screen and (max-width: 767px) {
		// No padding on mobile, since navbar isn't fixed
		padding-top: 0px;
	}

	&.no-scroll {
		overflow: hidden
	}
	&.no-scroll-overlay {
		width: 100%;
		&:after {
			content: "";
			position: fixed;
			top: 0px;
			left: 0px;
			right: 0px;
			bottom: 0px;
			background-color: rgba(0, 0, 0, 0.25);
		}
	}
	&.no-scroll-overlay-nav {
		z-index: 1031;
	}
}

.brand-color {
	color: $brand-color;
}

.brand-bg-color {
	background-color: $brand-color;
	color: $brand-bg-text-color;
}

.navbar {
	// Navbar needs to be on top for the mobile nav to work (due to js that makes the page unscrollable)
	z-index: 1030;
	margin-bottom: 10px;
}

@media screen and (min-width: 768px) {
	// Fixed navbar on desktop, not fixed on mobile
	.navbar {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		border-width: 0 0 1px;
		margin-bottom: 20px;
	}
}

// Make headers slightly smaller than standard
h1 { font-size: 215%; }

h2 { font-size: 185%; }

h3 { font-size: 140%; }

h4 { font-size: 115%; }

h5 { font-size: 100%; }

h6 { font-size: 85%; }

h1, h2, h3, h4, h5, h6 {
	&:first-child {
		margin-top: 0;
	}
}

.container, .container-fluid {
	> h1, > h2, > h3, > h4, > h5, > h6 {
		&:first-child {
			//margin-top: $line-height-computed;
			margin-top: 10px;
		}
	}
}

input[type=radio], input[type=checkbox] {
	cursor: pointer;
}

.logo {
	background: url(../../images/entities/omnius/omnius_logo.png) no-repeat center;
	background-size: contain;
}

@media screen and (max-width: 767px) {
	.container > .panel, .container > .row > div > .panel {
		margin-left: -15px;
		margin-right: -15px;
		border-radius: 0;
		border-left: 0;
		border-right: 0;
	}
	.container > .nav-tabs {
		width: auto;
		margin-left: -15px;
		margin-right: -15px;
		padding-left: 15px;
		padding-right: 15px;
	}
}

.modal-open {
	overflow-y: scroll;
	padding-right: 0px !important;
}
.breadcrumb {
	line-height: 1.1;
	margin: 10px 0;
	font-size: 215%;
	padding: 0;
}

// Make breadcrumbs more usable on mobile (as a h1 replacement)
@media (max-width: $screen-xs-max) {
	.breadcrumb {
		margin-bottom: 5px;
		font-size: 140%;
		padding: 15px 0 5px 0;
	}
}

// Make exams diss flexbox behaviour
@media (max-width: $screen-xs-max) {
	.examQuestionContainer.flexbox, .examQuestionContainer .flexbox {
		display: block;
	}
}

.helper-toast {
	cursor: pointer;
	position: fixed;
	bottom: 20px;
	right: 20px;
	min-width: 200px;
}

.nicer-badge {
	position: absolute;
	z-index: 999;
	font-size: 0.9em;
	background-color: red;
	margin-left: 10px;
	margin-top: -6px;
	display: inline-block;
	line-height: 14px;
	font-weight: bold;
	vertical-align: middle;
	box-sizing: border-box;
	text-align: center;
	border-radius: 6px;
	padding-left: 5px;
	padding-right: 5px;
}

@media (min-width: $screen-xs-min) {
	.anchor-fixed-offset {
		position: absolute;
		top: -70px;
	}
}

.unsupported-features {
	@extend .alert;
	@extend .alert-danger;
	border-radius: 0;
	margin: 0;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 100;
	padding: 10px;
	font-size: 115%;
	display: none;

	// Don't show unsupported features by default (show it on a not-supported basis instead)
	li {
		display: none;
	}
}

@media (max-width: 767px) {
	.unsupported-features {
		text-align: center;
	}
}

// If any feature is not supported, show the container
.has-no-es5 .unsupported-features,
.has-no-audio .unsupported-features,
.has-no-fileinput .unsupported-features,
.has-no-filereader .unsupported-features,
.has-no-flexbox .unsupported-features {
	display: block;
}

// If cookies are not supported, hide the login form altogether
.has-no-cookies #login-form {
	display: none;
}

// If any feature is not supported, show it
.has-no-es5 .feature-es5,
.has-no-audio .feature-audio,
.has-no-cookies .feature-cookies,
.has-no-filereader .feature-files,
.has-no-fileinput .feature-files,
.has-no-flexbox .feature-flexbox {
	display: block !important;
}
