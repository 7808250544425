.course-summary-stat-container {
	display: inline-block;
	vertical-align: middle;
}

.course-summary-stat {
	display: inline-block;
	margin-left: 15px;
	margin-right: 25px;
	vertical-align: middle;
}

.course-summary-stat-container.flex {
	display: flex;
	margin-top: 20px;
	vertical-align: top;
}

.course-summary-stat-container.flex .course-summary-stat {
	flex: 1;
	margin: 0;
	vertical-align: top;
	> big {
		line-height: 20px;
		height: 24px;
		display: block;
	}
}

@media (max-width: $screen-xs-max) {
	.course-summary-stat-container {
		display: flex;
		margin-top: 20px;
		vertical-align: top;
	}
	.course-summary-stat {
		flex: 1;
		margin: 0;
		vertical-align: top;

		i {
			font-size: 150%;
		}

		> big {
			line-height: 20px;
			height: 24px;
			display: block;
		}
	}
}

@media (max-width: $screen-xs-max) {
	.examQuestionPanelContainer{
		width: 100%;
	}
}

.timeline-wrapper {
	margin: 20px 0 35px;
	position: relative;

	@at-root .timeline-icon {
		position: absolute;
		width: 20px;
		height: 20px;
		top: -18px;
		margin-left: -10px;
		line-height: 20px;
		text-align: center;
		color: #888;
	}

	@at-root a:hover .timeline-icon {
		color: black;
	}

	.tooltip-inner {
		text-align: center;
	}

	@at-root .timeline-icon-bottom {
		flex: 0 0 auto;
		margin-top: 5px;
		color: #888;
	}

	@at-root {
		.timeline-bounce {
			animation-name: timeline-bounce;
			animation-duration: 1s;
			animation-iteration-count: infinite;
			animation-timing-function: ease-in-out;
		}

		@keyframes timeline-bounce {
			0% { margin-top: 0px; }
			50% { margin-top: -3px; }
			100% { margin-top: 0px; }
		}
	}

	@at-root .timeline {
		display: flex;
		justify-content: space-between;
		width: 100%;
		border-radius: 20px;
		height: 4px;
		overflow: visible;

		> span {
			display: block;
			flex: 1 1 auto;
			flex-basis: 0;
			height: 4px;
		}
		> span + span {
			border-left: 6px solid #fff;
		}

		// SLightly different look on mobile (thicker line, smaller spaces)
		@media (max-width: $screen-xs-max) {
			height: 6px;
			> span {
				height: 6px;
			}
			> span + span {
				border-left: 4px solid #fff;
			}
		}
	}
}

.timeline-gray { background-color: #ccc; }
.timeline-green { background-color: #5cb85c; }
.timeline-yellow { background-color: #f0d74e; }
.timeline-orange { background-color: #f0ad4e; }
.timeline-purple { background-color: #b94ef0; }
.timeline-red { background-color: #d9534f; }

// Timeline link (linking to this weeks instructions) covers slightly more space than the timeline week itself
.timeline-link {
	position: absolute;
	top: -7px;
	left: 0;
	right: 0;
	bottom: 0;
}

.course-summary {
	// Instructions look somewhat different on the summary page
	.course-edu-instructions {
		margin: 0;
		max-width: 600px;
	}

	.course-edu-instructions-header {
		display: none;
	}

	.course-edu-instructions-body {
		width: 100% !important;
	}

	// Header is not clickable for the summary version
	.course-edu-week h3 {
		text-align: left;
		background-color: transparent !important;
		border-bottom: 1px solid #ccc !important;
		cursor: unset;

		span {
			display: inline;
			margin-left: 5px;
		}
	}

	// Alerts need some custom margins to look decent on top of the timeline
	.alert {
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.alert + .timeline-wrapper {
		margin-top: 30px;
	}
}

// Navigation buttons for mobile version
.course-edu-nav-mobile {
	.btn > span {
		color: $text-muted;
	}

	.btn {
		border-radius: 0;
		border-top: 0;
		border-right: 0;
	}

	.btn:first-child {
		border-left: 0;
		border-radius: 0;
	}
}

.course-edu-week {
	border-bottom: 1px solid #ddd;

	&:last-child {
		border-bottom: 0;
	}

	h3 {
		display: block;
		margin: 0;
		padding: 12px 10px;
		cursor: pointer;
		font-size: 20px;
		border-bottom: 1px solid #ccc;

		@media (min-width: $screen-sm-min) {
			text-align: center;
		}
		@media (max-width: $screen-xs-max) {
			font-size: 22px;
			padding: 14px 10px;
		}
		span {
			@media (min-width: $screen-sm-min) {
				display: block;
			}
			@media (max-width: $screen-xs-max) {
				float: right;
				line-height: 22px;
				vertical-align: middle;
			}
		}
	}

	h3.collapsed {
		border: 0;
	}

	h3:hover {
		@extend .btn-default:hover;
	}
}

.course-edu-week:last-child {
	border-bottom: 0;
}

.course-edu-instructions {
	max-width: 800px;
	margin: 0 auto;
	padding: 0 15px;

	@at-root .course-edu-instructions-header {
		vertical-align: top;
		padding: 10px 45px 10px 45px;
		font-style: italic;
	}

	@at-root .course-edu-instructions-body {
		vertical-align: top;
		padding: 10px;
	}

	@at-root .course-edu-instructions-row {
		border-bottom: 1px solid rgba(0, 0, 0, 0.05);

		&:last-child {
			border-bottom: 0;
		}
	}

	@at-root {
		@media (max-width: $screen-xs-max) {
			.course-edu-instruction {
				padding-left: 10px;
				padding-right: 10px;
			}

			.course-edu-instructions-header {
				padding-left: 15px;
				font-weight: bold;
				div {
					font-weight: normal;
					display: inline-block;
				}
			}
		}
	}

	@at-root .course-edu-instruction + .course-edu-instruction {
		margin-top: 10px;
	}
}

.course-exams-row {
	border-top: 1px solid #ddd;

	&:first-child {
		border: 0;
	}
}

/* Styles related to the exam partial */
.exam-partial-icon {
	position: absolute;
	//top: 5px;
	top: 50%;
	left: 10px;
	transform: translateY(-50%);
}

.exam-partial-row {
	position: relative; /* relative to allow for absolutely positioned .exam-partial-icon */
	padding: 5px 0 5px 0;
}

@media (min-width: $screen-sm-min) {
	.exam-partial-row {
		/* Vertically center the row children */
		display: flex;
		align-items: center;
	}

	.exam-partial-name {
		/* Padding to make room for the icon */
		padding-left: 40px;
	}
}

@media (max-width: $screen-xs-max) {
	.exam-partial-row {
		padding-top: 10px;

		.exam-partial-name, .exam-partial-info a {
			/* Increase size of links on mobile for easier clicking (and name, for	*/
			font-size: 120%;
		}
	}

	/* Add some paddings to the columns so it looks nicer */
	.exam-partial-state, .exam-partial-info { padding: 0 0 10px 40px; }
	.exam-partial-name { padding: 0 0 0 40px; }
}


.literature-popover {
	height: 150px;
}

/* If the partial is compact mode, it has the .exam-partial-compact class */
.exam-partial-compact {
	/* Force the "mobile look" on the columns */
	.exam-partial-state, .exam-partial-info, .exam-partial-name {
		width: 100% !important;
		float: left !important;
	}

	&.exam-partial-row {
		padding-top: 10px;
	}

	&.exam-partial-compact.exam-partial-row {
		/* Disable the flex display that is used for non-compact desktop views */
		display: block;
	}

	/* Add some paddings to the columns so it looks nicer */
	.exam-partial-state, .exam-partial-info { padding: 0 0 10px 40px; }
	.exam-partial-name { padding: 0 0 0 40px; }
}

#exam-timer-alert {
	position: fixed;
	bottom: 30px;
	left: 30px;
	z-index: 10;
	margin: 0;

	@media (max-width: $screen-xs-max) {
		bottom: 0px;
		left: 0px;
		border-radius: 0;
		width: 100%;
	}
}

/* CSS-rules for the students exam booking view */
.occasion {
	line-height: 30px;
	vertical-align: middle;
	border-radius: 4px;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.10);
	border: 1px solid rgba(0, 0, 0, 0.10);
	margin-bottom: 5px;
	padding: 5px;
	display: block;
	color: #000
}

.occasion:hover {
	color: #000;
}

.occasion-full{
	cursor: default;
	background-color: $state-danger-bg;
	color: #000;
}

.occasion-full:hover, .occasion-full:visited {
	background-color: $state-danger-bg !important;
	color: #000;
}

.occasion-full div {
	opacity: 0.9;
}

@media (min-width: $screen-sm-min) {
	.resource-video-row {
		margin-bottom: 20px;
	}
}

@media (max-width: $screen-xs-max) {
	.resource-video-column {
		margin-bottom: 20px;
	}
}
