
.discussion-post {
	position: relative;
	@media screen and (min-width: 768px) {
		padding-left: 80px;
	}

	.discussion-avatar {
		position: absolute;
		left: 0px;
		top: 0px;
		border-radius: 3px;
		overflow: hidden;
		border: 1px solid rgba(0, 0, 0, 0.25);
		white-space: normal; // .user-link has white-space: pre, which breaks avatars, so we need to unset it

		@media screen and (max-width: 767px) {
			left: 8px;
			top: 11px;
			width: 32px;
			height: 32px;
			border-radius: 32px;
			> .avatar {
				width: 32px !important;
				height: 32px !important;
				line-height: 32px !important;
				font-size: 12px !important;
			}
		}
	}
	.panel-default:not(.panel-info) > .discussion-poster {
		background: #fff;
	}

	@at-root .discussion-poster {
		@media screen and (max-width: 767px) {
			padding-left: 48px;
		}
	}

	@at-root .discussion-body {
		min-height: 80px;
	}

	@at-root .discussion-post-date {
		@media screen and (min-width: $screen-sm-min) {
			float: right;
		}
	}
}

