
.time-matrix-timeline {
	display: block;
	padding: 2px;
	margin-bottom: 4px;
	border: 1px solid #000;
	text-overflow: ellipsis;
	background-color: #fff;
	height: 25px;
	border-radius: 6px;
	white-space: nowrap;
	overflow: hidden;
	z-index: 2;
	text-align: center;
	opacity: 0.9;
}

.time-matrix-timeline-new {
	background-color: #f2ff9c !important;
}

.time-matrix-underlay {
	position: relative;
	z-index: 1;
	margin-bottom: 20px;
	height: 100%;
	display: flex;
}

.time-matrix-timeline-container {
	position: absolute;
	width: 100%;
	z-index: 4;
	margin-top: 25px;
}

.time-matrix-underlay-block {
	position: absolute;
	display: inline-block;
	height: 100%;
	vertical-align: text-bottom;
}

.time-matrix-underlay-flex {
	text-align: center;
	border-right: 1px solid #ddd;
	flex: 1;
}

.time-matrix-underlay-flex:last-child {
	border-right: unset;
}

.time-matrix-underlay-flex-header {
	width: 100%;
	border-bottom: 1px solid #ddd;
	background-color: #fff;
	display: block;
}

.time-matrix-underlay-flex:nth-child(odd) {
	background: repeating-linear-gradient(-45deg, #fff, #fff 5px, #ddd 5px, #ddd 9px);
}

.time-matrix-vacation {
	background-color: #f0d74e;
	border-right: 1px solid #f0d74e !important;
}

.time-matrix-container .time-matrix-vacation:nth-child(odd) {
	background: repeating-linear-gradient(-45deg, #f0d74e, #f0d74e 5px, #fff 5px, #fff 9px);
}

.time-matrix-sickleave {
	background-color: #f0ad4e;
	border-right: 1px solid #f0ad4e !important;
}

.time-matrix-container .time-matrix-sickleave:nth-child(odd) {
	background: repeating-linear-gradient(-45deg, #f0ad4e, #f0ad4e 5px, #fff 5px, #fff 9px);
}

.time-matrix-apl {
	background-color: #b94ef0;
	border-right: 1px solid #b94ef0 !important;
}

.time-matrix-container .time-matrix-apl:nth-child(odd) {
	background: repeating-linear-gradient(-45deg, #b94ef0, #b94ef0 5px, #fff 5px, #fff 9px);
}


