// Slightly increase font size of Quill elements
div.ql-container {
	font-size: 14px;
}

// Translate some tooltip texts from Snow theme
.ql-snow .ql-tooltip {
	&::before {
		content: "Besök länk:";
	}

	&[data-mode=link]::before {
		content: "Ange länk:";
	}

	a.ql-action::after {
		content: 'Ändra';
	}

	&.ql-editing a.ql-action::after {
		content: 'Spara';
	}

	a.ql-remove::before {
		content: 'Ta bort';
	}
}

// Style our custom autocomplete tooltip
// NOTE: Generally needs !important to overwrite snowtheme rules
.ql-autocompletetooltip {
	padding: 7px 12px !important;

	&::before {
		content: "" !important;
		margin-right: 0 !important;
		line-height: unset !important;
	}

	a {
		vertical-align: -7px;
	}

	input[type=text] {
		width: 220px !important;

		/* Overwrite settings that quill snow theme modifies with those from .form-control */
		font-size: 14px !important;
		height: 34px !important;
		margin: unset !important;
		padding: 6px 12px !important;
	}

	.ql-autocomplete {
		display: inline-block;
	}
}

// Give our custom toolbar item an icon
.ql-comboxlink::before {
	font-size: 15px;
	font-weight: bold;
	line-height: 15px;
	vertical-align: 1px;
	content: "\f003";
}

// Hide empty format containers
.ql-snow .ql-formats:empty {
	display: none;
}

// Fix quill issue #851 by just not hiding it
.ql-out-bottom {
	visibility: visible !important;
}

// Remove the icon from comboxlinks in the contenteditable div, because they interfere with editing
.ql-editor[contenteditable='true'] .user-combox {
	padding-left: 0;

	&:before {
		content: '' !important;
	}
}