a[href*="youtube.com/"], a[href*="youtu.be/"] {
	display: inline-block;
	width: 200px;
	height: 151px;
}

.youtube-video {
	display: inline-block;
	vertical-align: middle;
	font-size: 0;
	position: relative;
	border-radius: 5px;
	overflow: hidden;
	width: 200px;
	height: 150px;
	> img {
		width: 200px;
		border-radius: 5px;
		border: 1px solid #666;
	}
	&:after {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background-image: url(../../images/youtube-overlay.png);
		background-position: center;
		background-repeat: no-repeat;
	}

	/* Mobile */
	@media screen and (max-width: 767px) {
		display: block;
		> img {
			width: 100%;
		}
	}
}

.youtube-video.youtube-video-inline {
	border-radius: 3px;
	> img {
		width: auto;
		height: 24px;
		vertical-align: middle;
		border-radius: 3px;
	}
	&:after {
		background-size: auto 16px;
	}
}

#cinema-overlay {
	display: block;
	position: fixed;
	background-color: rgba(0, 0, 0, 0.9);
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 999998;
}

#cinema-wrapper {
	display: block;
	position: fixed;
	left: 50%;
	top: 50%;
	width: 960px;
	height: 540px;
	transform: translate(-50%, -50%);
	z-index: 999999;
	background-color: #000;
	outline: 10px solid rgba(0, 0, 0, 0.9);
	> iframe {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}

	@media screen and (max-width: 960px) {
		width: 90%;
		outline: 0;
		height: 0;
		padding-bottom: 56.25%;
		padding-top: 25px;
	}

	/* Mobile */
	@media screen and (max-width: 767px) {
		outline: 0;
		width: 80%;
		height: 0;
		padding-bottom: 56.25%;
		padding-top: 25px;
	}
}

#cinema-helper {
	position: absolute;
	top: 20px;
	right: 20px;
	color: #fff;
}

#mobile-cinema-wrapper {
	display: block;
	position: relative;
	width: 100%;
	padding-top: 56.25%;
	> iframe {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}
}

@media screen and (max-width: 767px) {
	//.youtube-video {
	//	display: none;
	//}
}

@media screen and (min-width: 768px) {
	#mobile-cinema-wrapper {
		display: none;
	}
}
