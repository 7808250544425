.mobile-datepicker[readonly] {
	background-color: #fff;
}

.loading-icon {
	text-shadow: none;
}

// Hide stuff that Vue has not rendered yet
[v-cloak] {
	display: none;
}