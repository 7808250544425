// An alert with a white background (like a panel)
.alert-default {
	background-color: #fff;
	border: 1px solid #ddd;
}
.alert-hover.alert-default:hover {
	background-color: #e6e6e6 !important;
}


.alert-hover.alert-success:hover {
	background-color: darken($alert-success-bg, 5%) !important;
}
.alert-hover.alert-info:hover {
	background-color: darken($alert-info-bg, 5%) !important;
}
.alert-hover.alert-warning:hover {
	background-color: darken($alert-warning-bg, 5%) !important;
}
.alert-hover.alert-danger:hover {
	background-color: darken($alert-danger-bg, 5%) !important;
}
