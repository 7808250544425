
.input-group-select-container {
	display: table-cell;
	border-collapse: separate;
	box-sizing: border-box;
	vertical-align: top;
	width: 46px;
}

.input-group-select-container img {
	vertical-align: 0px;
	margin-right: 5px;
}

.input-group-select-container span {
	vertical-align: 3px;
}

.input-group-select-addon {
	padding: 8px 7px 10px;
	//padding: 10px 7px 11px;
	font-size: 14px;
	font-weight: normal;
	line-height: 1;
	color: #555555;
	text-align: center;
	border: 1px solid #ccc;
	border-right: none;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	display: table-cell;
	border-collapse: separate;
	box-sizing: border-box;
}