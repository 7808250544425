// Make it smaller
.cc_container {
	padding: 10px !important;
	border-right: 0 !important;
	border-left: 0 !important;
	border-bottom: 0 !important;
}

// Make button bootstrap themed
.cc_banner-wrapper .cc_container .cc_btn {
	@extend .btn, .btn-success
}