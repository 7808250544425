// Put the icon in the top right corner of the overlay instead of on top of the lightbox
.featherlight-close-icon {
	position: fixed !important;
	top: 20px !important;
	right: 20px !important;
	width: auto !important;
	background: none !important;
	font-family: FontAwesome !important;
	font-size: 3em;
	color: #fff !important;

	&::before {
		content: '\f00d'; // fa-times
	}
}

// Replace white border/padding with dark outline
.featherlight-content {
	border: 0 !important;
	padding: 0 !important;
	outline: 10px solid rgba(0, 0, 0, 0.9);
}

// Make overlay darker
.featherlight:last-of-type {
	background-color: rgba(0, 0, 0, 0.9) !important;
}

// Give elements with this class a little zoom icon top right
.featherlight-zoom {
	display: inline-block;
	position: relative;

	&::before {
		position: absolute;
		top: -8px;
		right: -5px;
		font-family: FontAwesome;
		content: '\f00e'; // fa-search-plus
		font-size: 140%;
		color: #fff;
		// black 1px solid outline
		text-shadow: -1px -1px 0 #000,
		1px -1px 0 #000,
		-1px 1px 0 #000,
		1px 1px 0 #000;
	}
}