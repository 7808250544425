.quicksearch-form {
	padding: 3px 15px;
	min-width: 250px;
}

.quicksearch-button {
	color: white;
	height: 34px;
}

.quicksearch-history {
	@media(max-width: $screen-xs-max) {
		font-size: 20px;

		h3 {
			font-size: 24px;
		}
	}

	h3 {
		margin-top: 10px;
		margin-bottom: 5px;
	}
}

.quicksearch-history a {
	display: table;
	width: 100%;
}

.quicksearch-history-id, .quicksearch-history-name {
	display: table-cell;
}

.quicksearch-history-id {
	width: 70px;
	width: 8ch; /* character width; not supported by all browsers */
}
