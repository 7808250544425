@media (min-width: $screen-sm-min) {
	.nav.nav-tabs + .panel.panel-default {
		border-top: 0;
		margin-top: 0;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
}

@media (max-width: 767px) {
	.nav-tabs {
		display: flex;
		width: 100%;

		> li {
			flex: auto;
			display: block;
			overflow: hidden;

			&.nav-tab-33 {
				width: 33.33333%;
			}

			> a {
				padding: 10px 3px;
				text-align: center;
				display: block;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}

	.responsive-pagination {
		.pagination {
			text-align: center;
			display: table;
			width: 100%;

			> li {
				display: table-cell;
				width: 1%;
				vertical-align: top;

				> a, > span {
					display: block;
					vertical-align: top;
					float: none;
					padding: 6px 0px;
					height: 33px;
					line-height: 21px;
				}
			}
			li:not(.active) {
				> span {
					font-size: 80%;
				}
			}
		}
	}
}

@media (min-width: 768px) {
	.pagination-no-margin .pagination {
		margin: 0;
	}
}

.nav-pills-sm > li > a {
	padding: 5px 10px;
	font-size: 12px;
	line-height: 1.5;
	border-radius: 3px;

	> .badge {
		font-size: 85%;
		margin-top: 2px;
		padding: 2px 5px;
	}
}
.nav-tree {
	&, ul {
		list-style: none;
		padding: 0px;
		margin: 0px;
	}
	ul {
		margin-left: 15px;
		li {
			position: relative;
			display: block;
			> a {
				position: relative;
				display: block;
				padding: 5px 10px;
				&:hover, &:focus {
					text-decoration: none;
					background-color: #eeeeee;
				}
			}
		}
	}
}

.list-group-heading {
	position: relative;
	display: block;
	padding: 7px 15px;
	margin-bottom: -1px;
	background-color: #fbfbfc;
	border: 1px solid #ddd;
	color: #666;
}