.panel-accordion {
	border-bottom: 1px solid #ddd;

	&:last-child {
		border-bottom: 0;
	}

	.collapse, .collapsing {
		font-size: 16px;
	}

	h3 {
		display: block;
		margin: 0;
		padding: 20px 20px 20px 30px;
		cursor: pointer;
		font-size: 20px;
		user-select: none;
		background-color: $brand-color;
		color: $brand-bg-text-color;

		&.collapsed {
			border: 0;
		}

		&:hover {
			background-color: darken($brand-color, 10%);
		}

		&:before {
			font-family: "FontAwesome";
			content: "\f078"; // chevron-down
			color: grey;
			font-size: 75%;
			margin-right: 5px;
			/* imitates fa-fw */
			display: inline-block;
			width: 1.28571429em;
			text-align: center;
		}

		&.collapsed:before {
			content: "\f054"; // chevron-right
		}

		// Bigger font on mobile
		@media (max-width: $screen-xs-max) {
			font-size: 22px;
			padding: 14px 10px;
		}
	}
}

