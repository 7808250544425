$brand-color: #3099cc !default;
$brand-logo: '../../images/entities/omnius/omnius_logo.png' !default;
$brand-navbar-logo: $brand-logo !default;

.logo {
	background: url($brand-logo) no-repeat center;
	background-size: contain;
}

@media screen and (max-width: 767px) {
	#user-nav-container {
		margin-left: -15px !important;
	}
	//This name is required to be exactly this, if you change this also change
	// the function initMobileNavigation in main.js
	.student-navbar-collapse > ul > li {
		float: left;
		width: 25%;
		> a {
			padding-top: 15px;
			padding-bottom: 15px;
		}
		@media screen and (max-width: 560px) {
			width: 33%;
		}
		@media screen and (max-width: 420px) {
			font-size: 13px;
		}
		@media screen and (max-width: 370px) {
			width: 50%;
			> a {
				padding-top: 5px;
				padding-bottom: 5px;
			}
		}
	}
}

@media(max-width: 767px) {
	#omnius-navbar-container {
		border-left: 0px;
		border-right: 0px;
	}

	#top-navbar-collapse {
		margin: 0px;
		left: 0px;
	}

	#top-navbar-collapse .dropdown-menu {
		background-color: #6b961d;
	}
}

.navbar-brand {
	background-color: #fff !important;
	margin-top: -10px;
	margin-bottom: -10px;
	height: 70px;
	width: 70px;
	padding: 7px 7px;
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.5);
	margin-left: 0 !important;
	position: absolute;
	z-index: 999;
	> div {
		z-index: 100;
		width: 56px;
		height: 56px;
		background: url($brand-navbar-logo) no-repeat center;
		background-size: contain;
		margin-top: 0px;
	}
}

.navbar-default {
	border-top: 15px solid #f6f6f6;
	background: $brand-color;
	border-radius: 0px;
	min-height: 50px;

	.navbar-nav {
		padding-left: 40px;
		> li {
			> a {
				color: #fff;
				padding: 8px 15px 10px;
				height: 50px;

				&:hover, &:focus {
					background-color: rgba(0, 0, 0, 0.1) !important;
					color: #fff !important;
				}
				> div {
					line-height: 13px;
					font-size: 90%;
					> i {
						font-size: 120%;
						margin-bottom: 6px;
					}
				}
			}
		}
		.open > a {
			background-color: rgba(0, 0, 0, 0.1) !important;
			color: #fff !important;
		}
		.open .dropdown-menu > li > a {
			@media (max-width: 767px) {
				color: #fff;
			}
		}
	}

	.navbar-toggle {
		border-color: #fff;

		&:hover, &:focus {
			background-color: #333;
		}

		.icon-bar {
			background-color: #fff;
			transition-duration: 0.4s;
			transform-origin: 50% 50%;
			&:nth-child(2) {
				transform: translateY(6px) rotateZ(45deg);
			}
			&:nth-child(3) {
				transform: scaleX(0.1);
			}
			&:nth-child(4) {
				transform: translateY(-6px) rotateZ(-45deg);
			}
		}
	}

	.navbar-toggle.collapsed {
		border-color: #fff;
		.icon-bar {
			background-color: #fff;
			&:nth-child(2) {
				transform: translateY(0px) rotateZ(0deg);
			}
			&:nth-child(3) {
				transform: scaleX(1);
			}
			&:nth-child(4) {
				transform: translateY(0px) rotateZ(0deg);
			}
		}

	}
	.navbar-collapse {
		border-color: rgba(0, 0, 0, 0.25);
		border: 0;
		.collapsing, collpase.in {
			.nav {
				padding-top: 10px;
			}
		}
	}
}

.mega-dropdown {
	position: static !important;
}

.mega-dropdown-container {
	@media(min-width: 768px) {
		.open .dropdown-toggle {
			&:after {
				border-bottom: 15px solid $brand-color; /* BRAND BG COLOR */
				border-left: 17px solid transparent;
				border-right: 17px solid transparent;
				content: "";
				left: 15px;
				position: absolute;
				bottom: -20px;
				z-index: 1500;
			}
		}
	}

	@media(max-width: 767px) {
		padding-left: 0px !important;
	}
}

.mega-dropdown-menu {
	margin-top: 15px !important;

	> li > a:hover {
		background-color: rgba(0, 0, 0, 0.1) !important;
		color: #fff !important;
	}


	@media(min-width: 768px) {
		padding: 5px 0px 0px 5px;
		width: 95%;
		margin-left: 2.5%;
		border: none;
		border-radius: 4px !important;
		> li {
			list-style: none;
			display: inline-block;
			padding-right: 5px;
			padding-bottom: 5px;
			width: 20%;
			vertical-align: top;
			user-select: none;

			> a {
				display: table;
				border-radius: 4px;
				height: 75px;
				font-weight: normal;
				line-height: 1.428571429;
				color: #fff;
				white-space: normal;
				font-size: 90%;
				box-sizing: border-box;
				box-shadow: 2px 2px 2px -2px #333;
				background-color: darken($brand-color, 5%);
				width: 100%;

				> div {
					display: table-cell;
					vertical-align: middle;
					text-align: center;
				}
			}
		}
	}

	@media(min-width: 1400px) {
		> li {
			width: 10%;
		}
	}

	@media(max-width: 767px) {
		width: 100% !important;
		left: 0px;

		> li > a {
			border-bottom: 1px solid darken($brand-color, 5%); //#79aa21;
			margin-bottom: 5px;
			line-height: 30px !important;
		}
		> li:last-child > a {
			border-bottom: 0;
			margin-bottom: 10px;
		}
	}
}

@media (min-width: 768px) and (max-width: 1366px) {
	.navbar-default {
		position: absolute !important;
	}
}

@media (max-width: 767px) {
	.navbar-collapse {
		position: absolute;
		background-color: $brand-color;
		width: 100%;

		> ul > li {
			padding: 0px;
		}
	}
	.navbar-brand {
		margin-left: 30px !important;
	}
}

.user-link.user-teacher {
	/*color: #8100cc; }*/
	color: #c60084;
}

