@media screen and (max-width: $screen-xs-max) {
	.message-folder-panel {
		border: 0;
		background-color: unset;
		box-shadow: none;
		//margin-bottom: 0px;

		.panel-body {
			padding: 0px 15px;
		}

		.pagination-no-margin {
			margin-top: -15px;
			margin-bottom: -15px;
		}
	}
}

.message-preview {
	@extend .text-muted;

	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.message-search {
	max-width: 450px;
	margin-left: auto;

	// Hide search on mobile
	@media screen and (max-width: $screen-xs-max) {
		display: none;
	}
}

// Very specific identifier to overcome bootstraps specificity
a.list-group-item.message-thread {
	background-color: #f8f8f8;

	padding: 10px 0px !important;

	color: #222;

	&.unread {
		color: #000;
		background-color: #fff;
		.title, .date {
			font-weight: bold;
		}
	}

	@media screen and (max-width: $screen-sm-max) {
		.date {
			font-size: 85%; // .small
		}
	}

	@media screen and (max-width: $screen-xs-max) {
		.date {
			color: #777; // text-muted
		}

		.title {
			font-size: 115%;
		}
	}

	@at-root .message-thread-label, .message-thread-label-all {
		z-index: 1;
		position: absolute;
		height: 43px;
		width: 40px;
		left: -1px;
		top: -11px;
		cursor: pointer;
	}
}

@media screen and (max-width: $screen-xs-max) {
	.message-compose-btn-container {
		position: fixed;
		right: 10px;
		bottom: 10px;
		padding: 0;
		z-index: 10000;

		a {
			box-shadow: 0px 2px 10px 3px rgba(0,0,0,0.3);

			// .btn-round
			border-radius: 1000px;
			padding: 8px;
			line-height: 20px;
			width: 38px;
			height: 38px;
			text-align: center;
		}
	}
}

.messages-compose-button-mobile {
	position: fixed;
	right: 10px;
	bottom: 10px;
	box-shadow: 0px 2px 10px 3px rgba(0,0,0,0.3);
	z-index: 10000;
}

.messages-folder-button-mobile {
	position: fixed;
	left: 10px;
	bottom: 10px;
	border-radius: 1000px;
	box-shadow: 0px 2px 10px 3px rgba(0,0,0,0.3);
	z-index: 10000;

	&:focus {
		outline: none;
	}
}

@media screen and (max-width: 767px) {
	.messages-column {
		padding: 0;
		table.table tr {
			td:first-child, th:first-child {
				padding-left: 15px;
			}
			td.date, th.date {
				padding-right: 15px;
				text-align: right;
			}
		}
	}
}