.loading-icon {
	@extend .fa, .fa-cog, .fa-spin, .fa-fw, .brand-color;
	// In case it's used in a .btn with text shadow, remove it (looks strange).
	text-shadow: none;
}

// If the loading icon is inside a contextually colored .btn, unset its color
.btn-primary, .btn-success, .btn-info, .btn-warning, .btn-danger {
	.loading-icon {
		color: unset;
	}
}
