$brand-color: #86bc25;
$brand-bg-text-color: #fff;
$brand-logo: '../../images/entities/ntiskolan/ntilogo.png';

@import "themes/omnius/main";


.timeline-green {
	background-color: #7eb612;
}

