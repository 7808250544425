
.container-responsive {
	display: block;
	max-width: 100%;
}

.container-responsive-100 {
	padding-top: 100%;
}
.container-responsive-75 {
	padding-top: 75%;
}
.container-responsive-66 {
	padding-top: 66%;
}
.container-responsive-50 {
	padding-top: 50%;
}
.container-responsive-33 {
	padding-top: 33%;
}
.container-responsive-25 {
	padding-top: 25%;
}

.break-word {
	word-wrap: break-word;
}

.flex-nav {
	display: flex;
	padding: 0 10px;
	list-style: none;
	background-color: #FFF;
	border-top: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
	box-shadow: 0px 3px 3px 0px #ddd;
	margin: 0 0 20px;

	> li {
		flex: auto;
		> a, > span {
			display: block;
			text-align: center;
			overflow: hidden;
			text-overflow: ellipsis;
			padding: 10px 5px;
		}
		> a {
			text-decoration: none;

			&:focus, &:hover {
				background-color: #f8f8f8;
			}
		}
	}
}
@media screen and (max-width: 350px) {
	.flex-nav {
		font-size: 0.9em;
	}
}

.alert:last-child {
	margin-bottom: 0px;
}

.btn-round {
	border-radius: 1000px;
	padding: 8px;
	line-height: 20px;
	width: 38px;
	height: 38px;
	text-align: center;
}

.glyphicon1p5x { font-size: 1.5em; }
.glyphicon2x { font-size: 2em; }
.glyphicon3x { font-size: 3em; }
.glyphicon4x { font-size: 4em; }

.glyphicon-spin {
	animation: spin 1s infinite linear;
}
@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.btn-highlight {
	animation: highlight 2s infinite ease-in-out;
}

@keyframes highlight {
	0% {
	opacity: 1;
	}
	50% {
	opacity: 0.5;
	}
	100% {
	opacity: 1;
	}
}


.input-disabled {
	background-color: #eeeeee;
	cursor: not-allowed !important;
}

.pointer { cursor: pointer; }

.inline-block { display: inline-block; }
.inline { display: inline; }
.block { display: block; }
.relative { position: relative; }

.top { vertical-align: top; }
.middle { vertical-align: middle; }
.bottom { vertical-align: bottom; }

.text-bold { font-weight: bold; }
.text-italic { font-style: italic; }
.text-underline { text-decoration: underline; }

@media screen and (min-width: 768px) {
	.text-right-sm {
		text-align: right;
	}
}

// Icon colors
.icon-primary { color: $brand-primary; }
.icon-success { color: $brand-success; }
.icon-info { color: $brand-info; }
.icon-warning { color: $brand-warning; }
.icon-danger { color: $brand-danger; }

// Icon colors
.bg-icon-primary { background-color: $brand-primary; }
.bg-icon-success { background-color: $brand-success; }
.bg-icon-info { background-color: $brand-info; }
.bg-icon-warning { background-color: $brand-warning; }
.bg-icon-danger { background-color: $brand-danger; }

// Like boostraps .table-hover, but for any element
.hover {
	background-color: $table-bg-hover !important;
}

.square-40 {
	min-width: 20px;
	line-height: 40px;
	padding: 0 10px;
	text-align: center;
	height: 40px;
	position: relative;
	box-sizing: content-box;
}

.rounded {
	border-radius: 1000px;
}

.ma0 { margin: 0px !important; }
.ma5 { margin: 5px; }
.ma10 { margin: 10px; }
.ma15 { margin: 15px; }
.ma20 { margin: 20px; }

.mx0 { margin-left: 0px !important; margin-right: 0px !important; }
.mx5 { margin-left: 5px; margin-right: 5px; }
.mx10 { margin-left: 10px; margin-right: 10px; }
.mx15 { margin-left: 15px; margin-right: 15px; }
.mx20 { margin-left: 20px; margin-right: 20px; }

.my0 { margin-top: 0px !important; margin-bottom: 0px !important; }
.my5 { margin-top: 5px; margin-bottom: 5px; }
.my10 { margin-top: 10px; margin-bottom: 10px; }
.my15 { margin-top: 15px; margin-bottom: 15px; }
.my20 { margin-top: 20px; margin-bottom: 20px; }

.ml0 { margin-left: 0px !important; }
.ml5 { margin-left: 5px; }
.ml10 { margin-left: 10px; }
.ml15 { margin-left: 15px; }
.ml20 { margin-left: 20px; }
.ml30 { margin-left: 30px; }

.mr0 { margin-right: 0px !important; }
.mr5 { margin-right: 5px; }
.mr10 { margin-right: 10px; }
.mr15 { margin-right: 15px; }
.mr20 { margin-right: 20px; }
.mr30 { margin-right: 30px; }

.mt0 { margin-top: 0px !important; }
.mt5 { margin-top: 5px; }
.mt10 { margin-top: 10px; }
.mt15 { margin-top: 15px; }
.mt20 { margin-top: 20px; }
.mt30 { margin-top: 30px; }

.mb0 { margin-bottom: 0px !important; }
.mb5 { margin-bottom: 5px; }
.mb10 { margin-bottom: 10px; }
.mb15 { margin-bottom: 15px; }
.mb20 { margin-bottom: 20px; }
.mb30 { margin-bottom: 30px; }

.p0 { padding: 0 !important; }
.p5 { padding: 5px !important; }
.p10 { padding: 10px !important; }
.p15 { padding: 15px !important; }
.p20 { padding: 20px !important; }
.p30 { padding: 30px !important; }

.pt0 { padding-top: 0 !important; }
.pb0 { padding-bottom: 0 !important; }

.pre-wrap {
	white-space: pre-wrap;
}

.pre-line {
	white-space: pre-line;
}

.nowrap {
	white-space: nowrap;
}

.thick-border {
	border-width: 3px;
}

.flexbox {
	display: flex;
}

.h100 {
	height: 100%;
}
.w100 {
	width: 100%;
}

.overflow-hidden {
	overflow: hidden;
	text-overflow: ellipsis;
}

// TODO: This doesn't seen general enough to warrant being in helpers.scss
@media screen and (min-width: 768px) {
	.info-panel-right {
		margin-bottom: 10px;
		margin-left: 20px;
		float: right;
		clear: right;
		max-width: 250px;
		min-width: 140px;
		width: 50%;
	}
}

.breadcrumb > li + li:before {
	content: "/\00a0";
}

.soft-shadow {
	box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.35);
}

.shake {
	animation: shake 1s ease both;
	transform: translate(0, 0);
}

@keyframes shake {
	10%, 90% {
		transform: translate(-1px, 0);
	}

	20%, 80% {
		transform: translate(2px, 0);
	}

	30%, 50%, 70% {
		transform: translate(-4px, 0);
	}

	40%, 60% {
		transform: translate(4px, 0);
	}
}

// XS only
@media only screen and (max-device-width: 480px) {
	.xs-block {
		width: 100%;
	}
}