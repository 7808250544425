
.twitter-typeahead {
	display: block !important;
}

.twitter-typeahead .tt-hint[disabled] {
	background-color: transparent !important;
}

.twitter-typeahead .tt-input[disabled] {
	background-color: #eeeeee !important;
}

.tt-menu {
	background-color: $panel-bg;
	max-height: 50vh;
	overflow-y: auto;
	border: 1px solid transparent;
	border-radius: $panel-border-radius;
	@include box-shadow(0 1px 1px rgba(0,0,0,.05));
	@include panel-variant($panel-default-border, $panel-default-text, $panel-default-heading-bg, $panel-default-border);
	width: 100%;

	.tt-dataset {
		.tt-header {
			padding: $panel-heading-padding;
			border-bottom: 1px solid $panel-inner-border;
			@include border-top-radius(($panel-border-radius - 1));
			background-color: $panel-footer-bg;
		}
		.tt-suggestion {
			//padding: $panel-heading-padding;
			padding: 5px 10px;
			@include clearfix;
			cursor: pointer;

			&.tt-cursor, &:hover {
				background-color: $btn-primary-bg;
				color: $btn-primary-color;
			}
			.tt-highlight {
				background-color: $btn-warning-bg;
				font-weight: normal;
			}
		}
		.tt-empty {
			padding: $panel-heading-padding;
			@include clearfix;
		}
	}
}