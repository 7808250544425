
/*
 *	Bootstrap 4 style outline buttons
 */

.btn-primary-outline {
	@include button-variant($btn-primary-border, $btn-default-bg, $btn-primary-border);
}
// Success appears as green
.btn-success-outline {
	@include button-variant($btn-success-border, $btn-default-bg, $btn-success-border);
}
// Info appears as blue-green
.btn-info-outline {
	@include button-variant($btn-info-border, $btn-default-bg, $btn-info-border);
}
// Warning appears as orange
.btn-warning-outline {
	@include button-variant($btn-warning-border, $btn-default-bg, $btn-warning-border);
}
// Danger and error appear as red
.btn-danger-outline {
	@include button-variant($btn-danger-border, $btn-default-bg, $btn-danger-border);
}

.btn-group-justified {
	display: flex !important;
	>.btn {
		flex: auto !important;
		//width: auto !important; this breaks firefox
	}
}

// A slight shadow on button text to make buttons more readable
.btn {
	text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

.btn-default {
	text-shadow: none;
}

// Pretty much a label that looks like a button (for use in button groups, like inline-confirm)
.pseudo-label-btn {
	background-color: #fff !important;
	border-color: #ccc !important;
	box-shadow: none !important;
	cursor: default !important;
	z-index: 1 !important;
}
