.mysiwyg-light { position: absolute; left: -9999px; }
//.mysiwyg-light-wrapper { border: 1px solid #666; border-radius: 3px; background-color: #EEE; padding: 2px; }
.mysiwyg-light-wrapper { padding: 2px; }
.mysiwyg-light-editor { background-color: #FFF; color: #000; overflow: auto; height: 120px; padding: 5px 8px; box-shadow: inset 2px 2px 3px 1px #EEE; border: 1px solid #999; border-radius: 2px; }
.mysiwyg-light-editor:focus { border-color: #FC0; }

.mysiwyg-light-editor a { color: #00F; text-decoration: underline; }

.mysiwyg-light-toolbar { margin-bottom: 2px; }
//.mysiwyg-light-button { width: 24px; height: 24px; display: inline-block; vertical-align: middle; background-size: 20px 20px; background-position: center; background-repeat: no-repeat; border: 1px solid #EEE; cursor: pointer; }
.mysiwyg-light-button { width: 24px; height: 24px; display: inline-block; vertical-align: middle; background-size: 20px 20px; background-position: center; background-repeat: no-repeat; cursor: pointer; }
.mysiwyg-light-clear { background-image: url(/images/icons/mysiwyg-light/draw_eraser.png); margin-right: 10px; }
.mysiwyg-light-bold { background-image: url(/images/icons/mysiwyg-light/text_bold.png); }
.mysiwyg-light-italic { background-image: url(/images/icons/mysiwyg-light/text_italic.png); }
.mysiwyg-light-underline { background-image: url(/images/icons/mysiwyg-light/text_underline.png); }
.mysiwyg-light-header { background-image: url(/images/icons/mysiwyg-light/text_heading_3.png); background-size: 18px 18px; margin-left: 10px; }
.mysiwyg-light-link { background-image: url(/images/icons/mysiwyg-light/link.png); margin-left: 10px; background-size: 18px 18px; }
.mysiwyg-light-unlink { background-image: url(/images/icons/mysiwyg-light/link_break.png); background-size: 18px 18px; }
.mysiwyg-light-list-unordered { background-image: url(/images/icons/mysiwyg-light/text_list_bullets.png); margin-left: 10px; background-size: 18px 18px; }
.mysiwyg-light-list-ordered { background-image: url(/images/icons/mysiwyg-light/text_list_numbers.png); background-size: 18px 18px; }
.mysiwyg-light-image { background-image: url(/images/icons/mysiwyg-light/images.png); background-size: 18px 18px; margin-left: 10px; }
.mysiwyg-light-specialchars { background-image: url(/images/icons/mysiwyg-light/text_letter_omega.png); margin-left: 10px; }

.mysiwyg-light-sub { background-image: url(/images/icons/mysiwyg-light/font_subscript.png); margin-left: 10px; }
.mysiwyg-light-sup { background-image: url(/images/icons/mysiwyg-light/font_superscript.png); }

.mysiwyg-light-indent { background-image: url(/images/icons/mysiwyg-light/text_indent.png); margin-left: 10px; }
.mysiwyg-light-outdent { background-image: url(/images/icons/mysiwyg-light/text_indent_remove.png); }

.mysiwyg-light-button:hover { background-color: #FC0; }

.mysiwyg-light-editor::-webkit-scrollbar { width: 12px; }
.mysiwyg-light-editor::-webkit-scrollbar-button { height: 0px; }
.mysiwyg-light-editor::-webkit-scrollbar-track {	}
.mysiwyg-light-editor::-webkit-scrollbar-track-piece { background-color: rgba(0,0,0,0.15); border-radius: 1px; }
.mysiwyg-light-editor::-webkit-scrollbar-thumb { background-color: rgba(0,0,0,0.35); border-radius: 1px; }
.mysiwyg-light-editor::-webkit-scrollbar-corner {	}
.mysiwyg-light-editor::-webkit-resizer {	}

.mysiwyg-light-editor:focus { outline: 1px solid #FC0; }

.mysiwyg-inline { border: 1px solid #CCC; border-radius: 0; }
.mysiwyg-inline .mysiwyg-light-editor { height: auto !important; min-height: 100px; max-height: 60vh; border-radius: 0; border: 1px solid #CCC; }

#mysiwygLightSpecialCharDropdown { position: absolute; z-index: 1000; padding: 5px; border: 1px solid #999; box-shadow: 0px 3px 10px 3px rgba(0,0,0,0.25); background-color: #FFF; max-width: 192px; z-index: 50000; }
.mysiwygLightSpecialCharDropdownButton { display: inline-block; width: 24px; height: 24px; line-height: 24px; text-align: center; cursor: pointer; }
.mysiwygLightSpecialCharDropdownButton:hover { background-color: #FEC; }

.mysiwyg-light-wrapper.resizable {	}
.mysiwyg-light-resize-handle { height: 6px; margin-top: 3px; cursor: s-resize; background-image: url(images/moveHandle.png); background-position: center; background-repeat: no-repeat; }

.mysiwyg-light-statusbar { margin-top: 5px; }
.mysiwyg-light-statusbar-inner { background-color: white; padding: 1px 4px; border: 1px solid #888; border-radius: 3px; }
.mysiwyg-light-statusbar-item { display: inline-block; }
.mysiwyg-light-statusbar-item + .mysiwyg-light-statusbar-item { margin-left: 20px; }
