
.user-link {
	position: relative;
	white-space: pre;
	display: inline-block;

	// Give a hint that those with data-user-id have a hover effect by changing cursor style
	&[data-user-id] {
		cursor: default;

		// If it's a link, make it a pointer instead
		&a {
			cursor: pointer;
		}
	}

	&.user-teacher, &.user-combox {
		padding-left: 1.2em;

		&:before {
			font-family: "Glyphicons Halflings";
			content: '';
			position: absolute;
			top: 0px;
			left: 0px;
		}
	}

	&.user-combox {
		color: #cc294a;
		&:before {
			font-family: "FontAwesome";
			content: "\f0e0";
		}
	}
	&.user-teacher {
		color: #8100cc;
		&:before {
			content: "\e008";
			font-size: 90%;
		}
	}
}

.user-status-available {
	color: #00bf00;
}

.user-status-busy {
	color: #bfa300;
}

.user-status-offline {
	color: #bf0009;
}

.user-card {
	position: absolute;
	min-width: 200px;
	background-color: #fff;
	overflow: hidden;
	white-space: nowrap;
	text-align: center;
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
	border: 2px solid #fff;
	z-index: 1000;
}

.user-card-banner {
	height: 70px;
	background: #67b4e5 url(../../images/banners/grass.png) no-repeat center;
	background-size: cover;
	margin-bottom: 25px;
}

.avatar {
	display: inline-block;
	width: 64px;
	height: 64px;
	line-height: 60px;
	text-align: center;
	font-size: 24px;
	color: #fff;
	background: #666 no-repeat center;
	background-size: cover;
	vertical-align: middle;
}

.user-card-image {
	position: absolute;
	left: 50%;
	top: 38px;
	margin-left: -32px;
	border-radius: 44px;
	border: 2px solid #fff;
}

.user-card-info {
	padding: 15px;
}

.user-card-name {
	line-height: 24px;
	font-size: 16px;
	white-space: nowrap;
}

.user-card-status {
	line-height: 20px;
	font-size: 11px;
}

.user-card-contacts {
	margin-top: 10px;
	text-align: left;
	> div + div {
		margin-top: 5px;
	}
}