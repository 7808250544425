
.file-download {
	font-size: 12px;
	margin-bottom: 10px;

	display: flex;
	justify-content: space-between;

	a.file-download-btn {
		/* NOTE: just "flex: 0" breaks IE11 */
		flex: 0 0 0;
		font-size: 28px;
		line-height: 28px;

		opacity: 0.5;
		&:hover {
			opacity: 1;
		}
	}

	.file-download-name {
		/* NOTE: just "flex: 1" breaks IE11 */
		flex: 1 0 0%;
		line-height: 32px;
		font-size: 16px;
		height: 32px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;

		.fa {
			float: left;
			margin-right: 8px;
			line-height: 32px;
			font-size: 32px;
			color: rgba(0, 0, 0, 0.5);
		}
	}
}

@media screen and (max-width: 767px) {
		a.file-download-preview {
			display: none;
		}
}

.file-download-extension {
	font-size: 10px;
	word-wrap: break-word;
	text-overflow: ellipsis;
	max-width: 250px;
}

#file-preview-overlay {
	display: block;
	position: fixed;
	background-color: rgba(0, 0, 0, 0.9);
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 999998;
}

#file-preview-wrapper {
	display: block;
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
	width: 70%;
	height: 70%;
	z-index: 999999;
	background-color: #000;
	outline: 10px solid rgba(0, 0, 0, 0.9);
	> iframe {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}

	#file-preview-loader {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		color: $brand-color;
	}
}

#file-preview-helper {
	position: absolute;
	top: 20px;
	right: 20px;
	color: #fff;
}
