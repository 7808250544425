.student-details-input {
	flex: auto;
	margin-right: 10px;
	flex-basis: 200px;
}

.student-details-group {
	display: flex;
	margin-right: -10px;
}

@media (max-width: 767px) {
	.student-details-input {
		flex: unset;
		width: 100%;
	}

	.student-details-group {
		display: block;
	}
}